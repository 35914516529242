import { IoMdSearch } from "react-icons/io";
import useReports from "./useReports";

import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import PageMainHeader from "../../components/commons/page-main-header";
import ONOCalendar from "../../components/commons/ono-calendar";
import { useContext, useEffect, useRef, useState } from "react";
import { deviceInfoContext } from "../../context";
import classes from "./style.module.scss";
import BottomMenu from "../../components/commons/bottom-menu";
import * as XLSX from "xlsx";
import moment from "moment";
import tradeStatusText from "../../helpers/tradeStatusText";
import noDataIcn from "../../assets/images/NodataAvailable.svg";
import Popover from "../../components/ui/popover";
import menu from "../../assets/images/menu/menu.svg";
import ReportsDocument from "./reportsDocument";
import print from "../../assets/images/print.svg";
import tradeStatusIcon from "../../helpers/getTradeStatusIcon";
import excel from "../../assets/images/menu/excel.svg";
import download_icon from "../../assets/images/download_icon.svg";
import disclaimer from "../../assets/images/disclaimer.svg";
import loading from "../../assets/images/loading.gif";
import { toast } from "react-toastify";
import Ledger from "./ledger";
import Subscription from "../login/subscription";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import { useLocation } from "react-router-dom";
const Reports = () => {
  const {
    tradeType,
    onChangeDateRange,
    reportDetails,
    handelSearch,
    searchText,
    activeTab,
    setActiveTab,
    tradeDetails,
    dateRange,
    setSearchText,
    subValidityRes,
  } = useReports();
  const location = useLocation();
  const { width, deviceType, right } = useContext(deviceInfoContext);
  const pdfInstanceRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const data = reportDetails.map((item, i) => ({
    index: i + 1, // 1-based index
    destTradeName: tradeType == "DEST" ? item.srcTradeName : item.destTradeName,
    id: tradeType == "DEST" ? item.traderSeq : item.traderSeq,
    truck: tradeType == "DEST" ? item.srcVehNum || "-" : item.destVehNum || "-",
    location:
      tradeType == "DEST"
        ? item.sourceAddressLine || "-"
        : item.destAddressLine || "-",
    dispatchDate: item.date,
    arrivalDate: item.arlDate,
    mode: item.tradeMode == "ORDER" ? "Order Mode" : "Commission Mode",
    status: tradeStatusText(item.tradeStatus),
  }));
  const handleExport = () => {
    // Define the static table headers and data
    const headers = [
      "#",
      "Destination / Source Trader",
      "Trade Id",
      "Truck Number",
      "Location",
      "Dispatch Date",
      "Arrival Date",
      "Trade Type",
      "Status",
    ];
    // Prepare the data for export
    const exportData = [
      headers, // Header row
      ...data.map((row) => [
        row.index,
        row.destTradeName,
        row.id,
        row.truck,
        row.location,
        row.dispatchDate,
        row.arrivalDate,
        row.mode,
        row.status,
      ]), // Data rows
    ];
    if (data.length > 0) {
      const worksheet = XLSX.utils.aoa_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, "Reports.xlsx");
    } else {
      toast.error("No data available to export excel.");
    }
  };
  const [openFilters, setOpenFilters] = useState(false);

  // Handle print action
  const handlePrint = async () => {
    setIsLoading(true);
    setOpenFilters(false);
    if (reportDetails.length > 0) {
      const blob = await pdf(
        <ReportsDocument
          invoiceData={reportDetails}
          tradeType={tradeType}
          fromDate={activeTab == "custom" ? dateRange.fromDate : ""}
          toDate={activeTab == "custom" ? dateRange.toDate : ""}
        />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      const isMobile = /iPhone|iPad|Android/i.test(navigator.userAgent);

      if (isMobile) {
        // For mobile users, open the PDF in a new tab
        window.open(url, "_blank");
      } else {
        // Create an iframe to print the PDF
        const iframe = document.createElement("iframe");
        iframe.style.position = "fixed";
        iframe.style.width = "0px";
        iframe.style.height = "0px";
        iframe.src = url;
        document.body.appendChild(iframe);

        iframe.onload = () => {
          if (iframe.contentWindow) {
            iframe.contentWindow.focus(); // Focus to prevent issues on some browsers
            iframe.contentWindow.print(); // Trigger the print dialog
          } else {
            console.error("iframe contentWindow is null");
          }
        };
      }
    } else {
      toast.error("No data available to generate PDF.");
    }
    setIsLoading(false);
  };
  // Function to generate and download the PDF
  const handleDownload = async () => {
    setIsLoading(true);
    setOpenFilters(false); // Show loading message after button click
    if (reportDetails.length > 0) {
      const pdfDoc = (
        <ReportsDocument
          invoiceData={reportDetails}
          tradeType={tradeType}
          fromDate={activeTab == "custom" ? dateRange.fromDate : ""}
          toDate={activeTab == "custom" ? dateRange.toDate : ""}
        />
      );
      const pdfBlob = await pdf(pdfDoc).toBlob();

      // Create a URL and trigger the download
      const url = URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Reports.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("No data available to generate PDF.");
    }
    // Hide loading after download is complete
    setIsLoading(false);
  };
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(
    !subValidityRes?.subValid && !subValidityRes?.trialPeriod ? true : false
  );
  useEffect(() => {
    if (!subValidityRes?.subValid && !subValidityRes?.trialPeriod) {
      setShowSubscriptionModal(true);
    }
  }, [location.pathname]);
  return (
    <>
      <div>
        <PageMainHeader />

        <>
          <div className="bg-white p-3 pb-0 gap-2 border-b shadow flex items-center justify-between">
            <div className="flex items-center bg-white">
              <span
                className={`text-md p-2 pt-0 inline-block w-14 text-center cursor-pointer ${
                  activeTab === "all" ? classes.activeTab : "tab"
                }`}
                onClick={() => {
                  setActiveTab("all");
                  setSearchText("");
                }}
              >
                All
              </span>
              <span
                className={`text-md p-2 pt-0 inline-block cursor-pointer ml-2 ${
                  activeTab === "custom" ? classes.activeTab : "tab"
                }`}
                onClick={() => {
                  setActiveTab("custom");
                  setSearchText("");
                }}
              >
                Custom
              </span>
              <span
                className={`text-md p-2 pt-0 inline-block cursor-pointer ml-2 ${
                  activeTab === "ledger" ? classes.activeTab : "tab"
                }`}
                onClick={() => {
                  setActiveTab("ledger");
                  setSearchText("");
                }}
              >
                Ledger
              </span>
            </div>
            <div>
              {" "}
              <div
                className="cursor-pointer"
                onClick={() => {
                  setOpenFilters(true);
                }}
              >
                <img src={menu} alt="menu" />
              </div>
              <Popover
                open={openFilters}
                onClose={() => {
                  setOpenFilters(false);
                }} // fullWidth={true}
                fromReports={true}
              >
                <div>
                  <ul className="text-sm">
                    <li
                      className="p-3 border-b cursor-pointer"
                      onClick={handlePrint}
                    >
                      <div className="flex gap-2 items-center">
                        <img src={print} className="w-8" />
                        <p className="text-sm">Print</p>
                      </div>
                    </li>
                    {/* <li className="p-3 border-b">Share</li> */}
                    <li
                      className="p-3 border-b cursor-pointer"
                      onClick={handleDownload}
                    >
                      <div className="flex gap-2 items-center">
                        <img src={download_icon} className="w-8" />
                        <p className="text-sm">
                          {isLoading ? "Generating PDF..." : "Download PDF"}
                        </p>
                      </div>
                    </li>
                    <li
                      className="p-3 cursor-pointer flex gap-2 items-center"
                      onClick={() => {
                        handleExport();
                        setOpenFilters(false);
                      }}
                    >
                      <img src={excel} className="w-8" />
                      <p className="text-sm">Export to Excel</p>
                    </li>
                  </ul>
                </div>
              </Popover>
            </div>
          </div>
          {activeTab === "ledger" && (
            <div className="bg-light-red p-3 flex justify-center items-center gap-2">
              <div>
                <img src={disclaimer} alt="" className="w-5 h-5" />
              </div>
              <div className="text-sm font-semibold">
                NOTE:{" "}
                <span className="text-sm">
                  This report only shows closed trades data
                </span>
              </div>
            </div>
          )}
          {activeTab !== "ledger" && (
            <div className={`relative w-full p-4 flex gap-2 items-center`}>
              <div style={{ width: "100%" }}>
                <input
                  className="w-full pr-5 px-6  py-2 border br-10 cursor-pointer"
                  placeholder="Search by Trade Name / Trade Id / Truck Number"
                  value={searchText}
                  onChange={handelSearch}
                />
                <IoMdSearch
                  className="absolute color-primary"
                  style={{
                    top: "1.8rem",
                    left: "22px",
                    fontSize: "1.2rem",
                  }}
                />
              </div>
            </div>
          )}
          {activeTab === "ledger" && (
            <div>
              <Ledger activeTab={activeTab} />
            </div>
          )}
          {activeTab === "custom" && (
            <div className="pb-4">
              {" "}
              <ONOCalendar onDateChange={onChangeDateRange} />
            </div>
          )}
          {activeTab !== "ledger" && (
            <div className={`${classes.reports}`}>
              <div
                style={{
                  width: width,
                  height:
                    activeTab == "all"
                      ? deviceType == "DESKTOP"
                        ? "calc(100vh - 24rem)"
                        : "calc(100vh - 29rem)"
                      : deviceType == "DESKTOP"
                      ? "calc(100vh - 27rem)"
                      : "calc(100vh - 30rem)",
                  overflowY: "auto",
                }}
                className={`${classes.tablecontainer}`}
              >
                <table style={{ width: width }} className="border">
                  <thead>
                    <tr className="text-sm">
                      <th
                        style={{
                          width: "50px",
                          position: "sticky",
                          top: 0,
                          left: 0,
                          zIndex: 99,
                        }}
                      >
                        S.No
                      </th>
                      <th
                        style={{
                          width: "160px",
                          position: "sticky",
                          top: 0,
                          left: 50,
                          zIndex: 99,
                        }}
                      >
                        {tradeType == "DEST" ? "Source" : "Destination"} <br />{" "}
                        Trader | Trade ID
                      </th>
                      <th style={{ width: "150px" }}>
                        Truck Number | <br />{" "}
                        {tradeType == "DEST"
                          ? "Source Location"
                          : "Dest Location"}
                      </th>
                      {/* <th style={{ width: "150px" }}> Location</th> */}
                      <th style={{ width: "120px" }}>Dispatch Date</th>
                      <th style={{ width: "120px" }}>Arrival Date</th>
                      <th style={{ width: "220px" }}>Status | Mode</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportDetails.length > 0 ? (
                      reportDetails?.map((item, index) => {
                        return (
                          <tr key={index} className="text-sm">
                            <td
                              style={{
                                position: "sticky",
                                top: 0,
                                left: 0,
                                zIndex: 2,
                              }}
                            >
                              {" "}
                              {index + 1}
                            </td>
                            <td
                              className=""
                              style={{
                                position: "sticky",
                                top: 0,
                                left: 50,
                                zIndex: 2,
                              }}
                            >
                              <span className="font-medium">
                                {tradeType == "DEST"
                                  ? item.srcTradeName
                                  : item.destTradeName}
                              </span>
                              <p className="color-sub-text">
                                Trade ID: {item.traderSeq}
                              </p>{" "}
                            </td>
                            <td style={{}}>
                              <p>
                                {tradeType == "DEST"
                                  ? item.srcVehNum || "-"
                                  : item.destVehNum || "-"}
                              </p>
                              <p>
                                {tradeType == "DEST"
                                  ? item.sourceAddressLine || ""
                                  : item.destAddressLine || ""}
                              </p>
                            </td>
                            {/* <td>
                          {tradeType == "DEST"
                            ? item.sourceAddressLine || "-"
                            : item.destAddressLine || "-"}
                        </td> */}
                            <td>
                              {item.date
                                ? moment(item.date).format("DD-MMM-YYYY")
                                : "-"}
                            </td>
                            <td>
                              {" "}
                              {item.arlDate
                                ? moment(item.arlDate).format("DD-MMM-YYYY")
                                : "-"}
                            </td>
                            <td>
                              <div className="font-medium flex gap-2 w-full">
                                <div className="" style={{ width: "20%" }}>
                                  {tradeStatusIcon(item.tradeStatus)}
                                </div>
                                <div
                                  className="flex flex-col"
                                  style={{ width: "80%" }}
                                >
                                  <div>{tradeStatusText(item.tradeStatus)}</div>
                                  <div className="color-sub-text">
                                    {" "}
                                    {item.tradeMode == "ORDER"
                                      ? "Order Mode"
                                      : "Commission Mode"}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div
                        className={`${classes.imageNoDiv} py-14`}
                        style={{ width: width }}
                      >
                        <img
                          src={noDataIcn}
                          alt="icon"
                          className={`${classes.imageNoData}`}
                        />
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="p-2 warn-color br-10 mt-3 text-sm">
                <span>
                  <strong>NOTE:</strong> If your ledger doesn’t match the number
                  of trades listed, please report the discrepancy right away to
                  avoid any disputes. Contact us immediately at{" "}
                  <a
                    href="https://wa.me/918337922222"
                    className="color-blue"
                    target="_blank"
                  >
                    +91 9606060398
                  </a>{" "}
                  or reply to this WhatsApp number.
                </span>
              </div>
              {isLoading && (
                <div className="absolute" style={{ width: width, top: "50%" }}>
                  <img
                    src={loading}
                    className="absolute color-primary"
                    style={{
                      height: "3rem",
                      width: "3rem",
                      left: "50%",
                      top: "50%",
                      zIndex: "9",
                      transform: "translateX(-50%)",
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div>
            <BottomMenu />
          </div>
        </>

        {showSubscriptionModal === true && (
          <>
            <Modal
              open={showSubscriptionModal}
              width={deviceType === "MOBILE" ? "80vw" : "400px"}
              onClose={() => {
                setShowSubscriptionModal(false);
              }}
              modalWidth={deviceType === "MOBILE" ? "100vw" : width}
              modalHeight="85vh"
              fromSubscription={true}
            >
              <>
                <Subscription />
              </>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};
export default Reports;
