import React, { useEffect, useState } from "react";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TRootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { TTraderDocs, TTraderKycDoc, TTraderOtp } from "../../types";
import Compressor from "compressorjs";
import { toast } from "react-toastify";
import { setSeconds } from "../../reducers/authSlice";

const useKycDocs = ({}: {}) => {
  const dispatch = useDispatch();
  const [aadhaar, setAadhaar] = useState<TTraderOtp | null>(null);
  const [isVerified, setIsVerified] = useState(false);

  const { handlePostCall, handleGetCall } = useAxiosClient();
  const { traderId } = useSelector((state: TRootState) => ({
    traderId: state.auth.traderId,
  }));
  const [kycDocs, setKycDocs] = useState<
    Array<{
      docType: "image" | "pdf";
      url: string;
      type: string;
      id: number;
      status: number;
    }>
  >([]);

  const [documents, setDocuments] = useState<TTraderDocs | null>();

  const getKycDocs = () => {
    handleGetCall<TTraderDocs>(
      `account/mandi/portal/account/getKycDocs/traderId${traderId}`
    ).then(({ data }) => {
      if (data) {
        let invoices: TTraderKycDoc[] = [];

        if (data.kycDocs != null && data.kycDocs.length > 0) {
          for (let image of data.kycDocs) {
            let docType: "pdf" | "image" = image.url.includes(".pdf")
              ? "pdf"
              : "image";
            if (image.name === "AADHAR") {
              let index = invoices.findIndex(
                (inv) => inv.name == "AADHAR-FRONT"
              );
              if (index < 0) {
                invoices.push({
                  ...image,
                  name: "AADHAR-FRONT",
                  docType: docType,
                });
              } else {
                invoices.push({
                  ...image,
                  name: "AADHAR-BACK",
                  docType: docType,
                });
              }
            } else {
              invoices.push({ ...image, docType: docType });
            }
          }
        }
        setDocuments({
          ...data,
          kycDocs: invoices,
        });
        setAadhaar({
          ...aadhaar,
          aadhaarNumber: data?.aadharNum,
          otp: aadhaar?.otp || "",
          requestId: aadhaar?.requestId || "",
          aadharVerified: aadhaar?.aadharVerified || 0,
        });
        setIsVerified(!!data?.aadharVerified);
      } else {
        setDocuments(null);
      }
    });
  };

  // image / pdf upload cheyadaniki

  const onUploadReceiptImage = async (
    type:
      | "AADHAR-FRONT"
      | "AADHAR-BACK"
      | "PAN"
      | "BANK_STATEMENT"
      | "ITR"
      | "TRADE_LICENSE"
      | "INVOICE",
    files: FileList | File[]
  ) => {
    const uploadPromises = Array.from(files).map(async (file) => {
      let fileType = "";
      if (file.type === "application/pdf") {
        fileType = "pdf";
      } else if (file.type.includes("image/")) {
        fileType = "image";
      }

      let formdata = new FormData();
      formdata.append("name", type);
      formdata.append("category", "PERSONAL");
      formdata.append("tpId", `${traderId}`);

      // Handle PDF uploads
      if (fileType === "pdf") {
        formdata.append("file", file, file.name.replace(" ", "-"));
        const imageUploadResponse = await handlePostCall<string[]>({
          URL: "/mandi/files/kyc-doc/upload",
          apiParams: formdata,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        });

        const url = imageUploadResponse.data[0];
        setKycDocs((prevDocs) => [
          { docType: "pdf", url: url, type: type, id: Date.now(), status: 1 },
          ...prevDocs,
        ]);
      } else {
        // Handle image compression and upload
        await new Promise<void>((resolve, reject) => {
          new Compressor(file, {
            quality: 0.8,
            success: async (compressedResult) => {
              try {
                formdata.append(
                  "file",
                  compressedResult,
                  file.name.replace(" ", "-")
                );
                const imageUploadResponse = await handlePostCall<string[]>({
                  URL: "/mandi/files/kyc-doc/upload",
                  apiParams: formdata,
                  config: {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  },
                });

                const url = imageUploadResponse.data[0];
                // Update state with image document details
                setKycDocs((prevDocs) => [
                  {
                    docType: "image",
                    url: url,
                    type: type,
                    id: Date.now(),
                    status: 1,
                  },
                  ...prevDocs,
                ]);
                resolve(); // Call resolve to indicate success
              } catch (error) {
                console.error("Error uploading image:", error);
                reject(error); // Call reject on failure
              }
            },
            error: (err) => {
              console.error("Compression error:", err);
              reject(err); // Call reject if compression fails
            },
          });
        });
      }
    });

    await Promise.all(uploadPromises);
  };

  const onRemoveReceiptImage = (
    image: string,
    isExist: boolean = false,
    index: number = 0
  ) => {
    if (isExist === true && documents?.kycDocs !== null) {
      let invoices = [...documents?.kycDocs!];
      let i = invoices.findIndex((img) => img.id === index);
      invoices[i] = { ...invoices[i], status: 0 };
      setDocuments({
        ...documents!,
        kycDocs: invoices,
      });
    } else {
      let newtapalInvoice = [...kycDocs];
      let index = newtapalInvoice.findIndex((img) => img.url === image);
      newtapalInvoice.splice(index, 1);
      setKycDocs(newtapalInvoice);
    }
  };

  const handleVerifyAadhar = () => {
    handlePostCall({
      URL: `payments/signzy/aadhar/otp`,
      apiParams: {
        // aadhaarNumber: aadhaar?.aadhaarNumber
        aadhaarNumber: 0,
      },
    })
      .then(({ data, status }) => {
        toast.success(status.message);
        dispatch(setSeconds(59));
      })
      .catch((err: any) => {
        toast.error(err.response.data.status.description);
      });
  };
  return {
    onUploadReceiptImage,
    onRemoveReceiptImage,
    kycDocs,
    setKycDocs,
    aadhaar,
    setAadhaar,
    isVerified,
    setIsVerified,
    handleVerifyAadhar,
    getKycDocs,
    documents,
  };
};
export default useKycDocs;
