import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TTraderDocs } from "../../types";
import { TRootState } from "../../store";
import {
  setKycDocsInfo,
  setMosResponse,
  setSrcBusinessAcc,
  setSubValidityResponse,
} from "../../reducers/authSlice";
import { getSubscriptionValidityStatus } from "./subscriptionService";
const useGetByIdDocs = () => {
  const { handleGetCall } = useAxiosClient();

  const [docs, setDocs] = useState<TTraderDocs | null>();

  const { traderId } = useSelector((state: TRootState) => ({
    traderId: state.auth.traderId,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetCall<TTraderDocs>(
      `account/mandi/portal/account/getKycDocs/traderId${traderId}`
    ).then(({ data }) => {
      if (data) {
        setDocs(data);
        dispatch(setKycDocsInfo(data));
        dispatch(setSrcBusinessAcc(data.bsnsAccnt[0].id));
        localStorage.setItem("mos_authkeys", JSON.stringify(data?.mosAuthKeys));
        dispatch(setMosResponse(JSON.stringify(data?.mosAuthKeys)));
        getSubscriptionValidityStatus(data?.mosAuthKeys.mosId)
          .then((response: any) => {
            const data = response.data.data;
            localStorage.setItem("subValidityRes", JSON.stringify(data));
            dispatch(setSubValidityResponse(JSON.stringify(data)));
          })
          .catch((error: any) => {
            console.log(error);
          });
      } else {
        setDocs(null);
      }
    });
  }, []);

  return { docs, setDocs };
};
export default useGetByIdDocs;
