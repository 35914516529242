import { useContext, useState } from "react";
import { deviceInfoContext } from "../../context";
import PageHeader from "../../components/commons/page-header";
import sub_icon from "../../assets/images/sub_icon.svg";
const Subscription = () => {
  const { width } = useContext(deviceInfoContext);

  return (
    <>
      <div className="py-10 pb-0 flex flex-col items-center justify-center">
        <div className="py-2 border-b">
          <p className="text-base font-medium text-center px-6">
            You have not Subscribed to any plan, please subscribe to continue
          </p>
        </div>
        <div className="py-10 px-6 border-b">
          <img
            src={sub_icon}
            className="flex items-center justify-center mb-5 mx-auto"
          />
          <p className="text-base font-medium text-center">
            Login into mOS with your mobile device to complete Subscription
          </p>
        </div>
        <div className="py-4">
          <span className="text-sm color-blue cursor-pointer font-medium">
            <a href="tel:+918337922222">Contact us</a>
          </span>
        </div>
      </div>
    </>
  );
};
export default Subscription;
