import { IoMdSearch } from "react-icons/io";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
} from "../../helpers/get-currency-number";
import noDataIcn from "../../assets/images/NodataAvailable.svg";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import useLedger from "./useLedger";
import { useSelector } from "react-redux";
import { TRootState } from "../../store";
import { TTrade } from "../../types";
import { deviceInfoContext } from "../../context";
import classes from "./style.module.scss";

const Ledger :React.FC<{activeTab : String}> = ({activeTab}) => {

  const { ledger } = useLedger();
  const { width, deviceType, right } = useContext(deviceInfoContext);
  const { tradeType} =
    useSelector((state: TRootState) => ({
      tradeType: state.auth.tradeType
    }));

  const [searchText, setSearchText] = useState("");
  const [filteredTraders, setFilteredTraders] = useState<TTrade[]>([]);

  const filterTraders = () => {
    const trimmedSearchText = searchText.trim().toLowerCase();

    const filtered = (ledger?.trades ?? []).filter((option) =>
      option.tradeId.toString().toLowerCase().includes(trimmedSearchText)
    );

    setFilteredTraders(filtered);
  };

  useEffect(() => {
    filterTraders();
  }, [searchText, ledger]);

  
  return (
    <>
      <div className={`relative w-full p-4 flex gap-2 items-center`}>
        <div style={{ width: "100%" }}>
          <input
            className="w-full pr-5 px-6  py-2 border br-10 cursor-pointer"
            placeholder="Search by Trade Id"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <IoMdSearch
            className="absolute color-primary"
            style={{
              top: "1.8rem",
              left: "22px",
              fontSize: "1.2rem",
            }}
          />
        </div>
      </div>

    <div className="p-4 border w-[91%] rounded-xl mb-4 ml-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="text-sm flex flex-col items-start">
            <span className="text-gray-500">Total Business</span>
            <p className="text-sm font-semibold">
              {getCurrencyNumberWithSymbol(ledger?.tradesAmt.businessAmt) || 0}
            </p>
          </div>

          <div className="text-sm flex flex-col items-start">
            <span className="text-gray-500">No. of Partners</span>
            <p className="text-sm font-semibold">
              {ledger?.tradesAmt.noOfPartners || 0}
            </p>
          </div>

          <div className="text-sm flex flex-col items-start">
            <span className="text-gray-500">Total Advance Utilized</span>
            <p className="text-sm font-semibold text-green-600">
              {getCurrencyNumberWithSymbol(ledger?.tradesAmt.advAmt) || 0}
            </p>
          </div>

          <div className="text-sm flex flex-col items-start">
            <span className="text-gray-500">Total Closed Trades</span>
            <p className="text-sm font-semibold">
              {ledger?.tradesAmt.closedCount || 0}
            </p>
          </div>
        </div>
      </div>

    
       <div className={`${classes.reports}`}>
          <div
            style={{
              width: width,
              height:
                activeTab === "all"
                  ? deviceType === "DESKTOP"
                    ? "calc(100vh - 24rem)"
                    : "calc(100vh - 29rem)"
                  : deviceType === "DESKTOP"
                  ? "calc(100vh - 27rem)"
                  : "calc(100vh - 30rem)",
              overflowY: "auto",
            }}
            className={`${classes.tablecontainer}`}
          >
        <table style={{ width: width }} className="border">
        <thead>
            <tr>
              <th className="px-3 py-2 text-left text-sm font-semibold" style={{ width: "50px" }}>#</th>
              <th className="px-3 py-2 text-left text-sm font-semibold" style={{ width: "120px" }}>
                Tx ID | Date
              </th>
              <th className="px-3 py-2 text-left text-sm font-semibold" style={{ width: "150px" }}>
                Item | Mode
              </th>
              <th className="px-3 py-2 text-left text-sm font-semibold" style={{ width: "100px" }}>
                {tradeType === "SOURCE" ? "Received" : "Paid"} (₹)
              </th>
              <th className="px-3 py-2 text-left text-sm font-semibold" style={{ width: "120px" }}>
                {tradeType === "SOURCE" ? "To be Received" : "To be Paid"} (₹)
              </th>
              <th className="px-3 py-2 text-left text-sm font-semibold" style={{ width: "140px" }}>
                Balance (₹)
              </th>
            </tr>
          </thead>

          <tbody>
            {filteredTraders != null && filteredTraders.length > 0 ? (
              filteredTraders.map((details, index) => (
                <React.Fragment key={details.tradeId}>
                  <tr className="bg-green-50">
                    <td colSpan={2} className="px-3 py-2">
                      <span className="color-blue font-medium">
                        {details.tradeId}
                      </span>
                      <br />
                      <span className="font-medium text-sm">
                        {details.date?.split(" | ")[0]}
                      </span>
                      <br />
                      <span className="color-sub-text text-sm">
                        {details.date?.split(" | ")[1] &&
                        moment(
                          details.date?.split(" | ")[1],
                          "YYYY-MM-DD",
                          true
                        ).isValid()
                          ? moment(details.date?.split(" | ")[1]).format(
                              "DD-MMM-YYYY"
                            )
                          : "-"}
                      </span>{" "}
                    </td>
                    <td colSpan={3} className="px-3 py-2 text-sm text-gray-800">
                      <span className="font-medium">TO: {details.item}</span>
                    </td>
                    <td
                      className={`px-3 py-2 text-'left' font-medium ${
                        details.balance < 0 ? "color-red" : "color-primary"
                      }`}
                    >
                      {getCurrencyNumberWithOutSymbol(details?.balance) || 0}
                    </td>
                  </tr>

                  {details.tradeReports.map((report, reportIndex) => (
                    <tr key={report.id} className="border-t text-sm">
                      <td className="px-3 py-2" style={{ width: "50px" }}>{reportIndex + 1}</td>
                      <td className="px-3 py-2 text-sm" style={{ width: "120px" }}>
                        <span className="font-medium">
                          {report.pmtReceiptDate?.split(" | ")[0]}
                        </span>
                        {(report.tradeStatus === "ARRIVED" ||
                          report.tradeStatus === "DISPATCHED") && (
                          <span
                            className={`ml-2 font-medium text-xs text-white br-10 p-1 ${
                              report.tradeStatus === "ARRIVED"
                                ? "bg-primary"
                                : "bg-red"
                            }`}
                          >
                            {report.tradeStatus}
                          </span>
                        )}
                        <br />
                        <span className="color-sub-text">
                          {report.pmtReceiptDate?.split(" | ")[1] &&
                          moment(
                            report.pmtReceiptDate.split(" | ")[1],
                            "YYYY-MM-DD",
                            true
                          ).isValid()
                            ? moment(
                                report.pmtReceiptDate.split(" | ")[1]
                              ).format("DD-MMM-YYYY")
                            : "-"}
                        </span>{" "}
                      </td>
                      <td className="px-3 py-2" style={{ width: "150px" }}>
                        <span className="font-medium text-sm">
                          {report.item?.split(" | ")[0]} {" "}
                          {report.item?.split(" | ")[1] && ` | ${report.item.split(" | ")[1]}`}
                        </span>
                        <br />
                        <span className="color-sub-text text-sm">
                          {report.item?.split(" | ")[2]}
                        </span>
                      </td>
                      <td className="px-3 py-2 font-medium" style={{ width: "100px" }}>
                        {getCurrencyNumberWithOutSymbol(report.paidRcvd) || 0}
                      </td>
                      <td className="px-3 py-2 font-medium" style={{ width: "140px" }}>
                        {getCurrencyNumberWithOutSymbol(report.tobePaidRcvd) ||
                          0}
                      </td>
                      <td
                        className={`px-3 py-2 font-medium ${
                          report.balance < 0 ? "color-red" : "color-primary"
                        }`}
                        style={{ width: "100px" }}
                      >
                        {getCurrencyNumberWithOutSymbol(report.balance) || 0}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <tr style={{ border: "none" }}>
                <td
                  colSpan={6}
                  className="text-center py-4"
                  style={{ border: "none" }}
                >
                  <div className="flex items-center justify-center py-2">
                    <img src={noDataIcn} alt="No data available" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      </div> 
    </>
  );
};
export default Ledger;
