import { useEffect, useState } from "react"; 
import {useSelector } from "react-redux";
import { TLedger } from "../../types";
import { TRootState } from "../../store";
import useAxiosClient from "../../hooks/useAxiosClient";

const useLedger = () => {
  const { handleGetCall } = useAxiosClient();
  
  const { tradeType, traderId, allReports, tradeDetails, allCustomReports } =
      useSelector((state: TRootState) => ({
        tradeType: state.auth.tradeType,
        traderId: state.auth.traderId,
        allReports: state.trades.allReports,
        tradeDetails: state.trades.tradeDetails,
        allCustomReports: state.trades.allCustomReports,
      }));

  const [ledger, setLedger] = useState<TLedger | null>();

  useEffect(() => {
    handleGetCall<TLedger>(
      `reports/mandi/trade-ledger/traderId/${traderId}/type/${tradeType}`
    ).then(({ data }) => {
      if (data) {
        setLedger(data);
      } else {
        setLedger(null);
      }
    });
  }, []);
  return { 
    ledger
  };
};
export default useLedger;
