import axios from "axios";
import { API_BASE_URL } from "./constants";
const mosloginData = JSON.parse(localStorage.getItem("mos_authkeys"));
const clientId = mosloginData?.clientId || "";
const clientSecret = mosloginData?.clientSecret || "";
const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["client-id"] = clientId;
instance.defaults.headers.common["client-secret"] = clientSecret;
export default instance;
